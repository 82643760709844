import { useRef, useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import ReplayIcon from '@mui/icons-material/Replay';
import DialogTitle from '@mui/material/DialogTitle';
import ScheduleIcon from '@mui/icons-material/Schedule';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import Autorenew from '@mui/icons-material/Autorenew';

import CodeIcon from '@mui/icons-material/Code';

import AlgorithmChat from './AlgorithmChat'


const AlgorithmEditor = ({ open, conversation, sendMessage, saveTimeout, complete, runOnly, addAction, close, isBuilding }) => {
  // const { max_retries } = actionData || 3;
  const timeout = conversation?.timeout || 60;
  const status = conversation?.status || 'pending';

  const [wasNearBottom, setWasNearBottom] = useState(true);

  const boxRef = useRef(null);
  
  useEffect(() => {
    const box = boxRef.current;
    if (!box) return;

    const checkIsNearBottom = () => {
      const tolerance = 200; 
      return box.scrollHeight - box.scrollTop - box.clientHeight < tolerance;
    };

    // Check and save the state every time the user scrolls
    const onScroll = () => setWasNearBottom(checkIsNearBottom());
    box.addEventListener('scroll', onScroll);

    // Cleanup the event listener when the component unmounts
    return () => box.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    const box = boxRef.current;
    if (box && wasNearBottom) box.scrollTop = box.scrollHeight;
  }, [conversation]);

  const start = (task) => {};

  // const handleMaxRetriesChange = (event) => saveMaxRetries(status, event.target.value, max_retries, building);
  const handleTimeoutChange = (event) => saveTimeout(event.target.value);

  return (
    <Drawer anchor="right" open={open} onClose={close}
      ModalProps={{ hideBackdrop: true, disableEnforceFocus: true, disableRestoreFocus: true, }}
      PaperProps={{ style: { width: 800, borderRadius: 5 } }}
      sx={{ backgroundColor: 'white', ".MuiDrawer-modal": { inset: 'unset !important' }, ".MuiPaper-root": { backgroundColor: '#04221a' } }}
    >
      <Box sx={{ mt: 1.5, mr: 1, mb: 0, ml: 3, display: 'flex', flexDirection: 'column', height: 'calc(100vh - 160px)' }}>
        <DialogTitle sx={{ pl: 2, mb: 3, fontWeight: 500, color: 'rgb(208, 255, 221)' }}>
          <CodeIcon sx={{ color: '#017322', marginRight: '10px', marginBottom: '-5px' }} /> Workflow builder - Chat
          <IconButton aria-label="close" onClick={close} size="small" sx={{ position: 'absolute', right: 10, top: 10, color: (theme) => theme.palette.grey[600] }} >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Box ref={boxRef} className="scrollable" sx={{ flexGrow: 1, pr: 3, overflowX: 'hidden', overflowY: 'scroll' }} >
          <AlgorithmChat conversation={conversation} sendMessage={sendMessage} start={start} 
            status={status} runOnly={runOnly} complete={complete} addAction={addAction} isBuilding={isBuilding} />
        </Box>
      </Box>

      <Box sx={{
        pr: 6, pb: 1.5, pl: 3, mb: 1.5, position: 'absolute', left: 0, bottom: 0, width: '100%', display: 'flex',
        flexDirection: 'row', justifyContent: 'flex-end', gap: 2.5, fontSize: 14, fontWeight: 400, color: '#e8ffef',
        '& .MuiSelect-select': { minWidth: '8px !important', paddingLeft: '4px' },
        '& .MuiInput-root::before': { borderBottomColor: '#0a593f' }
      }}>
        <Box sx={{ color: '#04231a', display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
        </Box>

        {/* <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
          <ReplayIcon sx={{ width: 15, mr: 1.1, color: '#00a52d' }} />
          <span>max code retries</span>
          <FormControl sx={{ ml: 0.5, mt: 0.4, color: 'white' }} size="small" variant="standard">
            <Select value={max_retries} onChange={handleMaxRetriesChange} sx={{ fontSize: 14 }} >
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={8}>8</MenuItem>
              <MenuItem value={16}>16</MenuItem>
            </Select>
          </FormControl>
        </Box> */}

        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
          <IconButton sx={{ fontFamily: 'Outfit', fontSize: 14, borderRadius: '5px', padding: '2px 12px 2px 8px', mr: 0}} onClick={complete}>
            <Autorenew sx={{ width: 17, mr: 1, color: '#00a52d' }} />
            check workflow
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
          <ScheduleIcon sx={{ width: 17, mr: 1, color: '#00a52d' }} />
          <span>max run time</span>
          <FormControl sx={{ ml: 0.5, mt: 0.4, color: 'white' }} size="small" variant="standard">
            <Select value={timeout} onChange={handleTimeoutChange} sx={{ fontSize: 14 }} >
              <MenuItem value={30}>30 s</MenuItem>
              <MenuItem value={60}>60 s</MenuItem>
              <MenuItem value={120}>2 m</MenuItem>
              <MenuItem value={300}>5 m</MenuItem>
              {/* <MenuItem value={600}>10 m</MenuItem>
              <MenuItem value={1800}>30 m</MenuItem>
              <MenuItem value={3600}>1 h</MenuItem> */}
            </Select>
          </FormControl>
        </Box>

      </Box>
    </Drawer>
  );
};

export default AlgorithmEditor;