import React, { useRef, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { keyframes } from "styled-components";
import ReactMarkdown from 'react-markdown';

import Box from '@mui/material/Box';
import SendIcon from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CommentIcon from '@mui/icons-material/Comment';
import WarningIcon from '@mui/icons-material/Warning';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';

import Logo from '../visuals/logo.png';
import ActionSelector from '../actions/ActionSelector';

// import CodeIcon from '@mui/icons-material/Code';
// import InputIcon from '@mui/icons-material/Check';
// import PlayIcon from '@mui/icons-material/PlayArrow';


const AlgorithmChat = ({ conversation, sendMessage, complete, start, status, runOnly, addAction, isBuilding }) => {
  const [message, setMessage] = useState('');
  const [isHoveringUserMessage, setIsHoveringUserMessage] = useState(false);


  const textFieldRef = useRef(null);

  useEffect(() => {
    textFieldRef.current.focus();
    document.getAnimations().forEach(animation => {
      animation.startTime = 0;
    });
  }); 

  const handleMessage = (event) => setMessage(event.target.value);
  const handleEditConversation = () => {};

  const handleSendMessage = () => {
    if (message === '') return;
    const truncatedMessage = message.length > 1000 ? message.substring(0, 1000) : message;
    sendMessage(truncatedMessage);
    setMessage('');
  };

  const handleKeyDown = (event) => {
    if ((status === 'assistant_message' || status === 'pending') && (event.ctrlKey || event.metaKey) && event.key === 'Enter') handleSendMessage();
  };

  const buildingAnimation = keyframes`
  0% { border-color: rgba(0, 162, 44, 1) }
  50% { border-color: rgba(0, 162, 44, 0) }
  80% { border-color: rgba(0, 162, 44, 1) }
`;

  const StyledTypography = styled(Typography)({
    '& h2': { fontWeight: 600, fontSize: 16, marginTop: 40 },
    '& h3': { fontWeight: 600, fontSize: 15, marginTop: 30 },
    '& p': { marginBottom: 15, lineHeight: '27px' },
    '& pre': { backgroundColor: '#052319', padding: 20, borderRadius: 4, marginBottom: 15 },
    '& code': { fontSize: 14 },
    '& a': { color: '#009F2A', textDecoration: 'none' },
    '& ol, ul': { marginTop: '15px', marginBottom: '15px' },
    '& ol': { counterReset: 'list-counter', listStyleType: 'none' },
    '& ul': { listStyle: 'none', '& li': { 
      position: 'relative', paddingLeft: '20px', marginBottom: '10px', '&::before': { content: '"\u00BB"' },
      },
    },
    '& ol > li': { position: 'relative', marginBottom: '10px', paddingLeft: '24px', textIndent: '0px',
      '&::before': { counterIncrement: 'list-counter', content: 'counter(list-counter)', color: '#009F2A', paddingRight: '10px', position: 'absolute', left: '0', fontWeight: 500,
      },
    },
    '& li::before': { content: 'counter(list-counter)', color: '#009F2A', paddingRight: '20px', fontWeight: 500, paddingRight: '10px', position: 'absolute', left: '0', },
    '& strong': { fontWeight: 600, },
  });

  const renderMessages = () => {
    if (!conversation) return null;
    return conversation.messages.map((message, message_index) => {
      if (message.role === 'assistant') {
        return (
          <>
            {message.content ? (
              <Box>
                <Box sx={{ borderRadius: '4px', padding: '21px 23px 9px 15px', marginBottom: '0px', marginTop: '10px', display: 'inline-flex', flexDirection: 'row' }} >
                  <Box sx={{ pr: 0, marginTop: '-1px', flex: '0 0 38px' }}>
                    <img src={Logo} alt="logo" width="24" height="24" />
                  </Box>
                  <Box sx={{ color: 'rgb(255 255 255 / 90%)', flex: '1', pr: 0.7 }}>
                    <p style={{ color: 'white', fontWeight: 600, paddingBottom: '10px' }}>Algonext</p>
                    <StyledTypography component="div">
                      <ReactMarkdown>
                        {message.content}
                      </ReactMarkdown>
                    </StyledTypography>
                  </Box>
                </Box>
              </Box>
            ) : null}
            {message.tool_calls?.map((tool_call, tool_index) => (
              <Box sx={{ borderRadius: '4px', padding: '8px', pl: 2.5, pr: 2, marginTop: '10px', marginBottom: '10px', marginLeft: '10px', display: 'inline-flex', flexDirection: 'row' }} style={{ backgroundColor: '#011c15', }}>
                <span style={{ fontWeight: 500, fontSize: 13, color: 'rgb(208, 255, 221)' }}>
                  {tool_call.function?.name === 'create_automation_files' ? ('modifying workflow') : null}
                  {tool_call.function?.name === 'test_automation' ? ('testing automation') : null}
                  {tool_call.function?.name === 'debug_code' ? ('debugging code') : null}
                  {tool_call.function?.name === 'automation_working' ? ('completing automation') : null}
                  {tool_call.function?.name === 'workflow_completed' ? ('completing workflow') : null}
                  {tool_call.function?.name === 'search_automation_library' ? ('searching for automations') : null}
                </span>
                {message_index == conversation.messages.length - 1 ? <CircularProgress sx={{ ml: 1.3, mt: 0.55, mr: 0.2, color: '#00dd3c' }} size="0.82rem" /> : <CheckIcon sx={{ ml: 0.8, color: '#00a52d', width: '0.75em' }} />}
              </Box>
            ))}
          </>
        );
      }
      // if (message.role === 'system') {
      //   return (
      //     <Box sx={{ borderRadius: '4px', padding: '9px 12px 9px 18px', marginBottom: '10px', marginBottom: '10px', marginRight: '10px', marginTop: '10px', display: 'inline-flex', flexDirection: 'row' }} style={{ backgroundColor: '#011c15', }}>
      //       <span style={{ fontWeight: 500, fontSize: 13.8, color: 'rgb(208, 255, 221)' }}>
      //         { message.content }
      //       </span>
      //     </Box>
      //   );
      // }
      if (message.role === 'user') {
        return (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, mb: 2 }} onMouseEnter={() => setIsHoveringUserMessage(true)} onMouseLeave={() => setIsHoveringUserMessage(false)} >
            <Box sx={{ padding: '0px 10px', marginBottom: '15px', display: 'inline-flex', flexDirection: 'row' }}>
              <Box  sx={{ color: 'white', lineHeight: '27px', pl: 6 }}>{message.content}</Box>
              <Box onClick={() => handleEditConversation(message.content, message_index)} sx={{ ml: 3, pt: 0.5 }}>
                {isHoveringUserMessage ?  <ClearIcon sx={{ color: '#d0ffdd', width: '20px', cursor: 'pointer' }} /> : <CommentIcon sx={{ color: '#d0ffdd', width: '20px' }} />}
              </Box>

            </Box>
          </Box>
        )
      }
    });
  };


  return (
    <>
      <Box sx={{ borderRadius: '4px', padding: '22px 22px 0px 15px', marginBottom: '30px', marginTop: '15px' }}>
        <Box>
          <Box sx={{ borderRadius: '4px', marginBottom: '30px', paddingTop: '5px',  display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ pr: 0, marginTop: '-1px', flex: '0 0 38px' }}>
              <img src={Logo} alt="logo" width="24" height="24" />
            </Box>
            <Box sx={{ color: 'white', flex: '1', pr: 0.7 }}>
              <p style={{ fontWeight: 600, paddingBottom: '10px' }}>Algonext</p>
              <p style={{ lineHeight: '27px', color: 'rgb(255 255 255 / 90%)', marginBottom: '10px' }}>Hi I'm Algonext, your personal SEO workflow automation builder. <br /> Tell me what you would like to automate and I will build and test the workflow for you.</p> 
              <p style={{ lineHeight: '27px', color: 'rgb(255 255 255 / 90%)', marginBottom: '10px' }}>My skills include technical SEO, keyword research, scraping, processing data, using LLMs/APIs and much more. I'm self-learning. When I have trouble getting something to work, I'll research new skills in the background and notify you by email.</p>
              <p style={{ lineHeight: '27px', color: 'rgb(255 255 255 / 90%)' }}>What would you like to automate? Try to be as detailed as you can.</p>
            </Box>
          </Box>
        </Box>
        {/* <ActionSelector active={conversation.messages && conversation.messages.length <= 1 && status !== 'generating' } addAction={addAction} /> */}
      </Box>
      {/* <IconButton onClick={complete} sx={{ backgroundColor: '#011c15', borderRadius: '3px', padding: '11px 20px 11px 24px', fontFamily: 'Outfit', fontSize: 14, fontWeight: 500 }}>
        Build and test workflow
        <PlayIcon sx={{ ml: 1.3, color: '#00a52d', width: '0.7em' }} />
      </IconButton> */}

      {renderMessages()}

      {status === 'generating' ? (
        <Box sx={{ borderRadius: '4px', padding: '9px 12px 9px 18px', ml: 2, marginBottom: '10px', display: 'inline-flex', flexDirection: 'row' }} style={{ backgroundColor: '#011c15', }}>
          <span style={{ fontWeight: 500, fontSize: 13, color: 'rgb(208, 255, 221)' }}>generating answer</span>
          <CircularProgress sx={{ ml: 1.3, mt: 0.55, mr: 0.2, color: '#00dd3c' }} size="0.82rem" />
        </Box>
      ) : null}

      {/* {status === 'max_retries_reached' ? (
        <Box sx={{ borderRadius: '4px', padding: '9px 12px 9px 18px', marginBottom: '10px', display: 'inline-flex', flexDirection: 'row' }} style={{ backgroundColor: '#011c15', }}>
          <span style={{ fontWeight: 500, fontSize: 13.8, color: 'rgb(208, 255, 221)' }}>max code retries reached</span>
          <WarningIcon sx={{ ml: 0.8, color: '#00a52d', width: '0.6em' }} />
        </Box>
      ) : null} */}

      {status === 'error' ? (
        <Box sx={{ borderRadius: '4px', padding: '9px 12px 9px 18px', ml: 2, marginBottom: '10px', display: 'inline-flex', flexDirection: 'row' }} style={{ backgroundColor: '#011c15', }}>
          <span style={{ fontWeight: 500, fontSize: 13, color: 'rgb(208, 255, 221)' }}>sorry, something went wrong</span>
          <WarningIcon sx={{ ml: 1, color: 'red', width: '0.6em' }} />
        </Box>
      ) : null}

      {/* {status === 'executing_code' ? (
        <Box sx={{ borderRadius: '4px', padding: '9px 12px 9px 18px', marginBottom: '10px', display: 'inline-flex', flexDirection: 'row' }} style={{ backgroundColor: '#011c15', }}>
          <span style={{ fontWeight: 500, fontSize: 13.8, color: 'rgb(208, 255, 221)' }}>running code</span>
          <CircularProgress sx={{ ml: 1.3, mt: 0.55, mr: 0.2, color: '#00dd3c' }} size="0.82rem" />
        </Box>
      ) : null} */}

      {/* {status === 'running' ? (
        <Box sx={{ borderRadius: '4px', padding: '9px 12px 9px 18px', marginBottom: '10px', display: 'inline-flex', flexDirection: 'row' }} style={{ backgroundColor: '#011c15', }}>
          <span style={{ fontWeight: 500, fontSize: 13.8, color: 'rgb(208, 255, 221)' }}>running automation</span>
          <CircularProgress sx={{ ml: 1.3, mt: 0.55, mr: 0.2, color: '#00dd3c' }} size="0.82rem" />
        </Box>
      ) : null} */}

      <Box sx={{ padding: '30px 0'}} />

      <Box sx={{ position: 'absolute', pl: 4, pr: 4, pb: 5, bottom: 30, left: 0, width: '100%' }}>

        {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '15px', paddingBottom: '15px' }}>
          <IconButton sx={{
            backgroundColor: '#011c15', borderRadius: '3px', padding: '9px 18px 10px 14px',
            fontFamily: 'Outfit', fontSize: 13, fontWeight: 500
          }} onClick={complete}>
            <CommentIcon sx={{ width: '17px', marginRight: '8px', color: '#00a52d' }} /> Check workflow 
          </IconButton>
        </Box> */}

        <TextField label={['unbuilt', 'paused_after_io_update', 'completed', 'run_error'].includes(status) ? null : 'Message Algonext...'} value={message}
          onChange={handleMessage} onKeyDown={handleKeyDown}
          inputRef={textFieldRef}
          fullWidth multiline sx={{
            "& fieldset": { border: 'none' }, "& .MuiInputBase-root": {
              borderRadiusTopLeft: '0 !important',
              backgroundColor: '#021c15 !important', 
              borderRadius: '4px',
              boxShadow: '0px 0px 45px 55px #04241a',
              borderLeft: isBuilding && (status == 'assistant_message' || status == 'pending') ? '4px solid rgba(0, 162, 44, 1)' : 'none',
              animation: isBuilding && (status == 'assistant_message' || status == 'pending') ? buildingAnimation + " 3s ease-out infinite" : null,
            }, "& .MuiInputLabel-root": { color: 'rgb(208, 255, 221)', paddingLeft: '20px', paddingTop: '8px' },
          }}
          InputProps={{
            style: { color: 'white', padding: '24px 24px', paddingLeft: '30px' },
            endAdornment: (
              <InputAdornment position="end">
                { status !== 'assistant_message' && status !== 'pending' ? (
                  <CircularProgress sx={{ color: '#00dd3c', width: '1.2rem' }} size="1.2rem" />
                ) : (
                  <IconButton
                    disabled={status !== 'assistant_message' && status !== 'pending'}
                    style={status !== 'assistant_message' && status !== 'pending' ? { color: '#04231a' } : { color: '#00a52d' }}
                    onClick={handleSendMessage}>
                    <SendIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }} />
      </Box>
    </>
  );
}

export default AlgorithmChat;